import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["addFields", "fields"]

  addFormField(event) {
    event.preventDefault()
    const content = this.fieldsTarget.innerHTML.replace(/EPOCH/g, new Date().valueOf())
    this.addFieldsTarget.insertAdjacentHTML('beforebegin', content)
  }

  removeFormField(event) {
    event.preventDefault()
    const item = event.target.closest(".nested-fields")
    if (item.querySelector("input[name*='id']").value) {
      item.querySelector("input[name*='_destroy']").value = 1
      item.style.display = 'none'
    } else {
      item.remove()
    }
  }
}
