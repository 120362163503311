import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["eventsModal"]

  connect() {
    this.open();
  }

  open() {
    this.eventsModalTarget.classList.remove('hidden')
  }

  close() {
    this.eventsModalTarget.classList.add('hidden')
  }
}
